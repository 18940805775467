import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './CodeBlock.css';

export function CodeBlock({
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLPreElement>,
  HTMLPreElement
>) {
  return (
    <Box
      className={Styles.pre}
      asChild
    >
      <pre {...props} />
    </Box>
  );
}
