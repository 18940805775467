import * as LabelPrimitive from '@radix-ui/react-label';
import { forwardRef } from 'react';
import classnames from 'classnames';

import type { LabelRecipeProps } from './Label.css';
import { LabelRecipe } from './Label.css';

const Label = forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & LabelRecipeProps
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={classnames(LabelRecipe(), className)}
    {...props}
  />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
