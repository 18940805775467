import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import classNames from 'classnames';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useMemo } from 'react';

import {
  extractBoxProps,
  Box,
  type BoxProps,
} from '@zenobius/ui-web-components-box';
import { Tokens } from '@zenobius/ui-web-uikit/tokens';

import * as Styles from './Styles.css';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;
const PopoverClose = PopoverPrimitive.Close;

const PopoverArrow = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & BoxProps
>(
  (
    { className, align = 'center', background, sideOffset = 4, ...props },
    ref,
  ) => {
    const backgroundVar = useMemo(() => {
      if (typeof background !== 'string') {
        return 'modal';
      }
      return background;
    }, [background]);

    const token = useMemo(() => {
      if (backgroundVar in Tokens.palette.background) {
        return Tokens.palette.background[backgroundVar];
      }
      return Tokens.palette.background.modal;
    }, [backgroundVar]);

    const vars = useMemo(() => {
      return assignInlineVars({
        [Styles.ArrowFillToken]: token,
      });
    }, [token]);

    return (
      <Box
        className={classNames(Styles.arrow, className)}
        style={vars}
        {...props}
        asChild
      >
        <PopoverPrimitive.Arrow />
      </Box>
    );
  },
);

type PopoverContentProps = React.ComponentPropsWithoutRef<
  typeof PopoverPrimitive.Content
>;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  PopoverContentProps & {
    sideAlign?: PopoverContentProps['align'];
  } & BoxProps
>(
  (
    { className, sideAlign = 'center', sideOffset = 4, children, ...props },
    ref,
  ) => {
    const boxProps = extractBoxProps(props);

    return (
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          ref={ref}
          align={sideAlign}
          sideOffset={sideOffset}
          className={classNames(Styles.container, className)}
          {...boxProps.excluded}
        >
          <Box
            className={classNames(Styles.block, className)}
            background="modal"
            border="modal"
            {...boxProps.extracted}
          >
            {children}
          </Box>
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    );
  },
);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverAnchor,
  PopoverArrow,
  PopoverClose,
};
