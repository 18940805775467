import * as React from 'react';
import classNames from 'classnames';
import type * as LabelPrimitive from '@radix-ui/react-label';

import { Label } from '@zenobius/ui-web-components-forms-label';
import {
  Box,
  extractBoxProps,
  type BoxProps,
} from '@zenobius/ui-web-components-box';

import { useFormField } from './useFormField';
import * as Styles from './FormLabelRecipe.css';

export const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & BoxProps
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField();
  const boxProps = extractBoxProps(props);

  return (
    <Box
      color="inputLabel"
      asChild
      {...boxProps.extracted}
    >
      <Label
        ref={ref}
        className={classNames(
          Styles.FormLabelRecipe({
            error: !!error,
            empty: true,
          }),
          className,
        )}
        htmlFor={formItemId}
        {...boxProps.excluded}
      />
    </Box>
  );
});
FormLabel.displayName = 'FormLabel';
