import classnames from 'classnames';
import * as Icons from '@radix-ui/react-icons';
import { useMemo } from 'react';

import { extractProps } from '@zenobius/react-extract-props';
import { Box } from '@zenobius/ui-web-components-box';
import type { BoxProps } from '@zenobius/ui-web-components-box';

import * as Styles from './Icon.css';
import type { IconSprinkles } from './Icon.css';

export const Icon = ({
  className,
  label,
  name,
  asChild,
  ...rest
}: Omit<BoxProps, 'children'> & {
  label?: string;
  name: keyof typeof Icons;
} & IconSprinkles) => {
  const parseProps = extractProps(
    rest,
    Object.keys(Styles.IconProperties.styles),
  );

  const IconComponent = useMemo(() => {
    return Icons[name];
  }, [name]);

  return (
    <Box className={classnames('icon', className)}>
      <Box
        asChild={asChild}
        className={classnames(
          'icon__glyph',
          Styles.sprinkles(parseProps.extracted),
        )}
        {...parseProps.excluded}
      >
        <IconComponent
          width=""
          height=""
        />
      </Box>
      {label && <span className={Styles.visuallyHidden}>{label}</span>}
    </Box>
  );
};
