import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';

/**
 * Extracts and excludes props from an object.
 * @alpha
 * @example
 * ```ts
 * const props = {
 *  a: 1,
 *  b: 2,
 *  c: 3
 * };
 *
 * const reference = {
 *  a: 1,
 *  b: 2
 * };
 *
 * const { extracted, excluded } = extractProps(props, Object.keys(reference));
 *
 * console.log(extracted); // { a: 1, b: 2 }
 *
 * console.log(excluded); // { c: 3 }
 * ```
 * @param props - Object to extract and exlucde props from
 * @param reference - Object to determine which props to extract or exclude
 * @returns Object with extracted and excluded props
 */
export function extractProps<
  Props extends {
    [K in keyof Props]: Props[K];
  },
  ReferenceKey extends string,
  Excluded extends Pick<Props, Exclude<keyof Props, ReferenceKey>>,
  Extracted extends Pick<Props, Extract<keyof Props, ReferenceKey>>,
>(props: Props, reference: ReferenceKey[]) {
  let referenceKeys = reference;

  if (!Array.isArray(reference)) {
    referenceKeys = [reference];
  }

  return {
    excluded: pickBy(
      props,
      (value, key) => !referenceKeys.includes(key as ReferenceKey),
    ) as Excluded,
    extracted: pick(props, referenceKeys) as unknown as Extracted,
  };
}
