import { useCallback, useEffect, useState } from 'react';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';

import { Form } from '@zenobius/ui-web-components-form';
import { Box } from '@zenobius/ui-web-components-box';
import {
  Logo,
  LogoPropSchema,
  useClientSideFaviconColourStorage,
  type LogoProps,
} from '@zenobius/ui-favicons';
import { Button } from '@zenobius/ui-web-components-button';

import { CodeExamplePopover } from './CodeExamplePopover';
import { ShareUrlButton } from './ShareUrlButton';
import { useLogoThemerForm } from './useLogoThemerForm';
import { LogoFormField } from './LogoFormField';
import { encode, useHashFormState } from './useHashFormState';
import { ButtonWithFeedback } from './ButtonWithFeedback';

export function LogoThemer() {
  const [status, setStatus] = useState<'idle' | 'loading' | 'saving' | 'saved'>(
    'loading',
  );
  const hashState = useHashFormState('theme', LogoPropSchema);
  const storage = useClientSideFaviconColourStorage();

  useEffect(
    function UpdateHash() {
      setStatus('idle');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Box
      background="card"
      borderRadius="Large"
      overflow="hidden"
      align="stretch"
      gap="Large"
      direction={{
        mobileSmall: 'column',
        tablet: 'row',
      }}
      style={{
        minWidth: '100%',
        minHeight: 480,
      }}
    >
      {status === 'loading' && (
        <Box
          flexGrow={1}
          align="center"
          justify="center"
        >
          <DotsHorizontalIcon />
        </Box>
      )}
      {status !== 'loading' && (
        <LogoThemerForm
          status={status}
          initial={hashState.value || storage.value}
          onStatusChange={setStatus}
          onSaveClick={(values) => {
            storage.set(values);
            hashState.set(values);
          }}
        />
      )}
    </Box>
  );
}

function pause(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function LogoThemerForm({
  initial,
  status,
  onStatusChange,
  onSaveClick,
}: {
  initial?: Partial<LogoProps> | null;
  status: 'idle' | 'loading' | 'saving' | 'saved';
  onStatusChange: (status: 'idle' | 'loading' | 'saving' | 'saved') => void;
  onSaveClick: (values: LogoProps) => void;
}) {
  const themerForm = useLogoThemerForm({
    initial,
  });

  const values = themerForm.form.getValues();

  const handleSaveForm = useCallback(async () => {
    onStatusChange('saving');
    onSaveClick(values);

    await pause(200);
    onStatusChange('saved');

    await pause(200);
    onStatusChange('idle');
  }, [onSaveClick, onStatusChange, values]);

  return (
    <Form {...themerForm.form}>
      <Box
        position="relative"
        direction="column"
        flexGrow={1}
        background="buttonSecondary"
        order={{
          mobileSmall: 0,
          laptop: 1,
        }}
      >
        <Box
          justify="flex-end"
          flexGrow={1}
          padding="Huge"
          position="absolute"
          zIndex="layer2__surface-over"
          top={0}
          right={0}
          gap="Large"
          color="buttonSecondary"
        >
          <CodeExamplePopover values={values} />
        </Box>
        <Box
          padding="Enourmous"
          justify="center"
          align="center"
          flexGrow={1}
        >
          <Logo
            width={196}
            height={196}
            {...values}
          />
        </Box>
        <Box
          flexGrow={0}
          justify="flex-end"
          padding="Large"
        >
          <ShareUrlButton hash={encode(values)} />
        </Box>
      </Box>
      <Box
        align="center"
        justify="center"
        padding="Large"
        direction="column"
      >
        <Box
          direction={{
            mobileSmall: 'row',
            tablet: 'column',
          }}
          gap="Normal"
        >
          <LogoFormField
            form={themerForm.form}
            name="arrowStroke"
            label="Arrow Stroke"
          />
          <LogoFormField
            form={themerForm.form}
            name="fgStroke"
            label="Foreground Stroke"
          />
          <LogoFormField
            form={themerForm.form}
            name="fgFill"
            label="Foreground Fill"
          />
          <LogoFormField
            form={themerForm.form}
            name="bgStroke"
            label="Background Stroke"
          />
          <LogoFormField
            form={themerForm.form}
            name="bgFill"
            label="Background Fill"
          />
          <LogoFormField
            form={themerForm.form}
            name="baseStroke"
            label="Base Stroke"
          />
        </Box>
        <Box
          flexGrow={1}
          direction="column"
          gap="Large"
          block="true"
        >
          <Button
            flexGrow={1}
            onClick={themerForm.reset}
            title="Reset the logo to its default colours."
          >
            Reset
          </Button>
          <ButtonWithFeedback
            flexGrow={1}
            onClick={handleSaveForm}
            feedback={status === 'idle' ? null : status}
            title="Save this icon as your custom logo."
          >
            Save
          </ButtonWithFeedback>
        </Box>
      </Box>
    </Form>
  );
}
