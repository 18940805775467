import classnames from 'classnames';
import type { InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { Box } from '@zenobius/ui-web-components-box';
import { FormInputRecipe } from '@zenobius/ui-web-components-form';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
} from '@zenobius/ui-web-components-popover';

import * as Styles from './ColorPaletteInput.css';
import { ColorChip } from './ColorChip';
import { ColorChipRadio } from './ColorChipRadio';

type ColorPaletteInputProps = InputHTMLAttributes<HTMLInputElement> & {
  invalid?: boolean;
  isDirty?: boolean;
  isValidating?: boolean;
  isTouched?: boolean;
  palette: Record<string, string>;
};
export const ColorPaletteInput = forwardRef<
  HTMLInputElement,
  ColorPaletteInputProps
>(
  (
    { className, palette, invalid, isDirty, isTouched, isValidating, ...props },
    ref,
  ) => {
    const value = typeof props.value === 'string' ? props.value : '';
    return (
      <Popover>
        <Box
          className={classnames(
            'color-palette-input',
            FormInputRecipe({
              dirty: !!isDirty,
              empty: !props.value,
              error: !!invalid,
              validating: !!isValidating,
            }),
            Styles.block,
          )}
          asChild
        >
          <PopoverTrigger>
            <ColorChip
              className={classnames(
                'color-palette-input-selected-chip',
                Styles.button,
                Styles.input,
              )}
              name={value}
              color={value ?? ''}
            />
          </PopoverTrigger>
        </Box>
        <PopoverContent
          direction="column"
          justify="center"
          sideAlign="start"
          side="left"
          gap="Small"
          background="buttonSecondaryHover"
          border="buttonSecondaryHover"
        >
          <PopoverArrow background="buttonSecondaryHover" />
          <ColorChipRadio
            color="currentColor"
            name="currentColor"
            onClick={() => {
              if (props.onChange) {
                props.onChange({
                  target: {
                    ...props,
                    value: 'currentColor',
                  },
                } as any);
              }
            }}
          />

          <ColorChipRadio
            color="transparent"
            name="transparent"
            onClick={() => {
              if (props.onChange) {
                props.onChange({
                  target: {
                    ...props,
                    value: 'transparent',
                  },
                } as any);
              }
            }}
          />

          <Box
            className={classnames(
              'color-palette-input-palette',
              Styles.palette,
            )}
          >
            {Object.entries(palette).map(([name, color]) => (
              <ColorChipRadio
                key={name}
                color={color}
                selected={props.value === color}
                onClick={() => {
                  if (props.onChange) {
                    props.onChange({
                      target: {
                        ...props,
                        value: color,
                      },
                    } as any);
                  }
                }}
              />
            ))}
          </Box>
        </PopoverContent>
      </Popover>
    );
  },
);
ColorPaletteInput.displayName = 'ColorPaletteInput';
