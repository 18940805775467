import type { ComponentProps } from 'react';
import React from 'react';

import { Box } from '@zenobius/ui-web-components-box';
import { Icon } from '@zenobius/ui-web-components-icon';

import * as Styles from './Heading.css';

export function Heading({
  level,
  children,
  title,
  ...props
}: {
  level: 1 | 2 | 3 | 4 | 5 | 6;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>) {
  const size = level || 1;
  const Element = `h${size}`;

  if (!props.id) {
    return <Element {...props}>{children}</Element>;
  }

  return (
    <Box
      className={Styles.block}
      asChild
    >
      <Element {...props}>
        <Box
          direction="row"
          alignItems="center"
          gap="Normal"
        >
          {children}
          <a
            title={title && `link to ${title}`}
            href={`#${props.id}`}
            className={Styles.link}
          >
            <span
              aria-label="a chain link icon"
              role="img"
              className={Styles.icon}
            >
              <Icon
                name="Link1Icon"
                size="small"
              />
            </span>
          </a>
        </Box>
      </Element>
    </Box>
  );
}

type ComposedHeadingProps = Omit<ComponentProps<typeof Heading>, 'level'>;

Heading.H1 = (props: ComposedHeadingProps) => (
  <Heading
    {...props}
    level={1}
  />
);

Heading.H2 = (props: ComposedHeadingProps) => (
  <Heading
    {...props}
    level={2}
  />
);

Heading.H3 = (props: ComposedHeadingProps) => (
  <Heading
    {...props}
    level={3}
  />
);

Heading.H4 = (props: ComposedHeadingProps) => (
  <Heading
    {...props}
    level={4}
  />
);

Heading.H5 = (props: ComposedHeadingProps) => (
  <Heading
    {...props}
    level={5}
  />
);

Heading.H6 = (props: ComposedHeadingProps) => (
  <Heading
    {...props}
    level={6}
  />
);
