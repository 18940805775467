import React from 'react';
import type { HTMLAttributes } from 'react';
import classNames from 'classnames';

import { Box } from '@zenobius/ui-web-components-box';
import { extractProps } from '@zenobius/react-extract-props';

import * as Styles from './FormItemRecipe.css';
import { FormItemContext } from './FormFieldContext';

export const FormItem = React.forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const id = React.useId();
  const formItemProps = extractProps(props, Styles.FormItemVariantNames);

  return (
    <FormItemContext.Provider value={{ id }}>
      <Box
        direction="column"
        asChild
      >
        <div
          ref={ref}
          className={classNames(
            Styles.FormItemRecipe(formItemProps.extracted),
            className,
          )}
          {...formItemProps.excluded}
        />
      </Box>
    </FormItemContext.Provider>
  );
});
FormItem.displayName = 'FormItem';
