import { useMemo } from 'react';
import { CodeIcon } from '@radix-ui/react-icons';
import dedent from 'dedent';

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
} from '@zenobius/ui-web-components-popover';
import { Box } from '@zenobius/ui-web-components-box';
import type { LogoProps } from '@zenobius/ui-favicons';
import { useMediaQueries } from '@zenobius/ui-web-uikit';

import { CopyContentButton } from './CopyContentButton';

export function CodeExamplePopover({ values }: { values: LogoProps }) {
  const media = useMediaQueries()
  const codeShareContent = useMemo(() => {
    return dedent(`
      #!bin/bash

      set -e

      declare -A theme

      theme[bgFill]="${values.bgFill}"
      theme[bgStroke]="${values.bgStroke}"
      theme[fgFill]="${values.fgFill}"
      theme[fgStroke]="${values.fgStroke}"
      theme[arrowStroke]="${values.arrowStroke}"
      theme[baseStroke]="${values.baseStroke}"
    `);
  }, [values]);

  return (
    <Popover>
      <Box
        asChild
        background="buttonSecondary"
        border="buttonSecondary"
        color="buttonSecondary"
        style={{ cursor: 'pointer' }}
      >
        <PopoverTrigger>
          <CodeIcon
            width={22}
            height={22}
          />
        </PopoverTrigger>
      </Box>
      <PopoverContent
        background="card"
        border="card"
        direction="column"
        gap="Normal"
        alignItems="flex-start"
        zIndex='layer0__dev'
        sideAlign={media.is('tablet') ?  "start" : "end"}
        side={media.is('tablet') ?  "right" : "bottom"}
      >
        <PopoverArrow background="card" />
        <Box
          flexGrow={1}
          direction="column"
          gap="Normal"
        >
          <Box
            background="base"
            borderRadius="Normal"
            padding="Normal"
          >
            <pre>{codeShareContent}</pre>
          </Box>
          <Box
            textSize="Tiny"
            color="informative"
            background="informative"
            padding="Small"
            borderRadius="Normal"
          >
            This is for me to copy into my build tools that generate my favicon.
          </Box>
        </Box>
        
        <CopyContentButton
          content={codeShareContent}
          copiedLabel="Copied"
        >
          Copy Theme Code
        </CopyContentButton>
      </PopoverContent>
    </Popover>
  );
}
