import classnames from 'classnames';
import { getMDXComponent } from 'mdx-bundler/client';

import { Box } from '@zenobius/ui-web-components-box';
import { Icon } from '@zenobius/ui-web-components-icon';
import { LogoThemer } from '@zenobius/ui-microapps-logothemer';

import { block } from './CmsContent.css';
import { Heading } from './components/Heading';
import { Link } from './components/Link';
import { Image } from './components/Image';
import { CodeBlock } from './components/CodeBlock';

export const CmsContent = ({
  content,
  className,
}: {
  content?: string;
  className?: string;
}) => {
  const Content = content ? getMDXComponent(content) : () => null;

  return (
    <Box
      className={classnames('cms-content', block, className)}
      direction="column"
      alignItems="stretch"
    >
      <Content
        components={{
          a: Link,
          h1: Heading.H1,
          h2: Heading.H2,
          h3: Heading.H3,
          h4: Heading.H4,
          h5: Heading.H5,
          h6: Heading.H6,
          img: Image,
          pre: CodeBlock,
          Box,
          Icon,
          LogoThemer,
        }}
      />
    </Box>
  );
};
