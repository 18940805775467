import classNames from 'classnames';
import { forwardRef } from 'react';
import type { HTMLAttributes } from 'react';
import { IconJarLogoIcon } from '@radix-ui/react-icons';

import { extractProps } from '@zenobius/react-extract-props';
import { Box } from '@zenobius/ui-web-components-box';

import { useFormField } from './useFormField';
import * as Styles from './FormMessageRecipe.css';
import type { FormMessageVariants } from './FormMessageRecipe.css';
import { FormMessageVariantNames } from './FormMessageRecipe.css';

export const FormMessage = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement> & FormMessageVariants
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;
  const messageProps = extractProps(props, FormMessageVariantNames);

  if (!body) {
    return null;
  }

  return (
    <Box
      asChild
      alignItems="flex-start"
      gap="Small"
    >
      <p
        ref={ref}
        id={formMessageId}
        className={classNames(
          Styles.FormMessageRecipe({
            invalid: !!props.invalid,
            isTouched: !!props.isTouched,
            isDirty: !!props.isDirty,
            isValidating: !!props.isValidating,
          }),
          className,
        )}
        {...messageProps.excluded}
      >
        {props.invalid && (
          <IconJarLogoIcon
            height={18}
            width={18}
          />
        )}
        {body}
      </p>
    </Box>
  );
});
FormMessage.displayName = 'FormMessage';
