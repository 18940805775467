import classnames from 'classnames';

import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './ColorPaletteInput.css';
import { ColorChip } from './ColorChip';

export function ColorChipRadio({
  color,
  name,
  selected,
  square,
  onClick,
}: {
  color: string;
  name?: string;
  selected?: boolean;
  square?: boolean;
  onClick?: () => void;
}) {
  return (
    <ColorChip
      onClick={onClick}
      color={color}
      name={name}
      className={classnames(
        'color-palette-input-chip-radio',
        Styles.buttonRadio,
        square && Styles.chip,
      )}
    >
      <label>
        <input
          placeholder=" "
          type="radio"
          value={color}
          checked={!!selected}
          style={{ display: 'none' }}
        />
        {name && (
          <Box
            background="base"
            padding="Small"
          >
            {name}
          </Box>
        )}
      </label>
    </ColorChip>
  );
}
