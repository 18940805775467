import { Palette } from '@zenobius/ui-web-uikit/palettes';

export const Colours = Object.entries(Palette).reduce(
  (acc, [name, palette]) => {
    return {
      ...acc,
      ...Object.entries(palette).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [`${name}_${key}`]: value,
        };
      }, {}),
    };
  },
  {},
);
