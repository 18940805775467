import classnames from 'classnames';
import { useId } from 'react';

import type { BoxProps } from '@zenobius/ui-web-components-box';
import { Box } from '@zenobius/ui-web-components-box';

import type { ButtonRecipeProps } from './ButtonRecipe.css';
import { ButtonRecipe } from './ButtonRecipe.css';

export function Button({
  primary,
  secondary,
  size,
  block,
  beforeElement,
  afterElement,
  asChild,
  children,
  ...props
}: BoxProps &
  ButtonRecipeProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    beforeElement?: React.ReactNode;
    afterElement?: React.ReactNode;
  }) {
  const id = useId();

  return (
    <Box
      className={classnames(
        'button',
        ButtonRecipe({
          primary,
          secondary,
          size,
          block
        }),
      )}
      asChild
      {...props}
    >
      {(!!asChild && children) || (
        <button aria-labelledby={`button-label-${id}`}>
          {beforeElement}
          <span id={`button-label-${id}`}>{children}</span>
          {afterElement}
        </button>
      )}
    </Box>
  );
}
