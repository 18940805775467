import type { ForwardedRef, PropsWithChildren, RefObject } from 'react';
import classnames from 'classnames';

import { extractProps } from '@zenobius/react-extract-props';
import type { SprinkleProps } from '@zenobius/ui-web-uikit/atoms';
import { SprinklePropertyKeys, Sprinkles } from '@zenobius/ui-web-uikit/atoms';

import { withDividers } from './sprinkles/dividers.css';
import { ComponentWithRef } from './ComponentWithRef';
import { Slot } from './Slot';

type AsChildProps<DefaultElementProps> = PropsWithChildren<
  { asChild?: boolean } & DefaultElementProps
>;

export type BoxProps = AsChildProps<
  {
    dividers?: boolean;
    className?: string;
    style?: object;
    inline?: boolean;
  } & React.HTMLAttributes<HTMLElement> &
    SprinkleProps
>;

export function extractBoxProps(props: BoxProps) {
  const boxProps = extractProps(props, SprinklePropertyKeys);
  const propsWithOutAsChild = extractProps(boxProps.excluded, ['asChild']);
  return {
    ...boxProps,
    propsWithOutAsChild,
  };
}

export const Box = ({
  className,
  dividers,
  inline,
  children,
  style,
  ...allProps
}: BoxProps &
  (
    | {
        asChild: true;
        ref?: never;
      }
    | {
        asChild?: undefined | false;
        ref?: RefObject<HTMLDivElement> | ForwardedRef<HTMLDivElement>;
      }
  )) => {
  const boxProps = extractBoxProps(allProps);
  const box = Sprinkles({
    display: (inline && 'inline') || 'flex',
    ...boxProps.extracted,
  });
  const classString = classnames(
    'box',
    className,
    dividers && withDividers,
    box,
  );

  if (allProps.asChild) {
    return (
      <Slot
        className={classString}
        style={style}
        {...boxProps.propsWithOutAsChild.excluded}
      >
        {children}
      </Slot>
    );
  }

  return (
    <ComponentWithRef
      ref={allProps.ref}
      className={classString}
      style={style}
      {...boxProps.propsWithOutAsChild.excluded}
    >
      {children}
    </ComponentWithRef>
  );
};
