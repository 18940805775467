import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './Image.css';

export function Image({
  ...props
}: React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>) {
  return (
    <Box className={Styles.block}>
      <img
        {...props}
        className={Styles.image}
        alt={props.alt}
      />
    </Box>
  );
}
