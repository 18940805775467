import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@zenobius/ui-web-components-form';
import type { LogoProps } from '@zenobius/ui-favicons';

import type { useLogoThemerForm } from './useLogoThemerForm';
import { ColorPaletteInput } from './ColorPaletteInput';
import { Colours } from './Colours';

export function LogoFormField({
  form,
  name,
  label,
}: {
  form: ReturnType<typeof useLogoThemerForm>['form'];
  name: keyof LogoProps;
  label: string;
}) {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem>
          <FormLabel
            display={{
              mobileSmall: 'none',
              tablet: 'inline',
            }}
          >
            {label}
          </FormLabel>
          <FormControl>
            <ColorPaletteInput
              palette={Colours}
              {...field}
              {...fieldState}
            />
          </FormControl>
          <FormMessage {...fieldState} />
        </FormItem>
      )}
    />
  );
}
