import { useEffect, useState } from 'react'

import { objectKeysUnsafe } from '@zenobius/core-typed-objects'

import { MediaQueries } from './contracts/layout/LayoutControct.css'

/**
 * Return a Map<keyof tyeof MediaQueries, boolean> of the current screen size
 */
function Compute() {
  const keys = objectKeysUnsafe(MediaQueries)
  const result = new Map<keyof typeof MediaQueries, boolean>()

  for (const key of keys) {
    const query = MediaQueries[key]
    const yesno = window.matchMedia(query).matches;
    result.set(key, yesno)
  }

  return result
}


export function useMediaQueries() { 

  const [queries, setQueries] = useState(Compute())

  useEffect(function ComputeMediaQueries() {
    const listener = () => {
      setQueries(Compute())
    }
  
    window.addEventListener('resize', listener)
  
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  const is = (query: keyof typeof MediaQueries) => {
    return queries.get(query) || false
  }

  return {
    is
  }
}
