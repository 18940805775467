import * as React from 'react';
import classNames from 'classnames';

import { extractProps } from '@zenobius/react-extract-props';

import { useFormField } from './useFormField';
import * as Styles from './FormDescriptionRecipe.css';
import type { FormDescriptionVariants } from './FormDescriptionRecipe.css';

export const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement> & FormDescriptionVariants
>(({ className, ...props }, ref) => {
  const descriptionProps = extractProps(
    props,
    Styles.FormDescriptionVariantNames,
  );
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={classNames(
        Styles.FormDescriptionRecipe(descriptionProps.extracted),
        className,
      )}
      {...descriptionProps.excluded}
    />
  );
});
FormDescription.displayName = 'FormDescription';
