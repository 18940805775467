export function Link({
  children,
  href,
  className,
}: React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>) {
  const isExternal = !!href && href.startsWith('http');

  if (isExternal) {
    return (
      <a
        className={className}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }

  return (
    <a
      className={className}
      href={href}
    >
      {children}
    </a>
  );
}
