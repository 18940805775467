import classnames from 'classnames';
import { useMemo, type PropsWithChildren } from 'react';

import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './ColorPaletteInput.css';

export function ColorChip({
  color,
  name,
  selected,
  className,
  ...props
}: PropsWithChildren<
  {
    color: string;
    name?: string;
    selected?: boolean;
  } & React.HTMLAttributes<HTMLElement>
>) {
  const style = useMemo(() => {
    if (color === 'transparent') {
      return {
        border: '1px solid var(--color-border)',
        backgroundSize: '20px 20px',
        backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
        // gradient of diagonal lines white/black
        backgroundImage: `linear-gradient(45deg, #ccc 25%, transparent 25%), linear-gradient(-45deg, #ccc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #ccc 75%), linear-gradient(-45deg, transparent 75%, #ccc 75%)`,
      };
    }

    if (color === 'currentColor') {
      return {
        // gradient of diagonal lines white/black
        backgroundImage: `repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255,255,255,.5) 5px, rgba(255,255,255,.5) 10px)`,
        border: '1px solid var(--color-border)',
      };
    }

    return {
      backgroundColor: color,
    };
  }, [color]);

  return (
    <Box
      key={name}
      title={name}
      className={classnames(
        'color-palette-input-chip',
        Styles.button,
        selected && Styles.buttonSelected,
        className,
      )}
      style={style}
      {...props}
    />
  );
}
